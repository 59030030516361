import React from "react";
import { Helmet } from 'react-helmet';
import CaptionedImage from "../Components/Shared/CaptionedImage";
import PageTitle from "../Components/Shared/PageTitle";
import Paragraph from "../Components/Shared/Paragraph";
import A from "../Components/Shared/A";
import CaptionedImages from "../Components/Shared/CaptionedImages";
import CTAContainer from "../Components/Shared/CTAContainer";

import dragon from "../Assets/art/MoonlightRipple.png"
import ranger from "../Assets/art/Arden.png"
import gunslinger from "../Assets/art/Mari.png"
import alchemist from "../Assets/art/Mars.png"
import comfort from "../Assets/art/ComfortFront.jpg"
import murrays from "../Assets/art/murraysallbig.png"
import dnd from "../Assets/art/PartyBig.png"
import catformer from "../Assets/art/catformer.png"
import displacer from "../Assets/art/DisplacerBeastIllusory.png"
import dragonGreen from "../Assets/art/DragonGreenYoung.png"
import fearie from "../Assets/art/FaerieQueenGloamingCourt.png"
import golem from "../Assets/art/IronGolem.png"
import minotaur from "../Assets/art/MinotaurSkeleton.png"
import owlbear from "../Assets/art/Owlbear.png"
import skyline from "../Assets/SSPortlandSkyline.png"

import { ROLL_20, COMMISSIONS } from "../Constants/externalUrls";

export default function Art() {
    return (
        <>
            <Helmet>
                <title>Tale-Top - Art</title>
            </Helmet>
            <PageTitle>Pixel Art</PageTitle>
            <CTAContainer>
                <A internal src="art/tokens">Check out my token gallery</A>
                <A src={ROLL_20}>Find my work on the Roll20 marketplace</A>
            </CTAContainer>
            <Paragraph>
                Pixel art is a digital art medium characterized by the careful placement of individual pixels in an image. The art has its roots in early game development, where memory limitations required it, but is comparable to much older forms of art such as mosaic and cross-stitching.
            </Paragraph>
            <Paragraph>
            The majority of my work has been dedicated to my project to create <A internal src="art/tokens">virtual table-top tokens</A> for every monster in the monster manual, but examples of my other work can be seen below!
            </Paragraph>
            <Paragraph>
                Commissions are available for character tokens and portraits on my <A src={COMMISSIONS}>Ko-Fi</A> starting at $16.
            </Paragraph>
            <CaptionedImage
                image={dragon}
                alt="An indigo water dragon with turquoise fins rests gracefully. It is named Moonlight Ripple."
                caption="In college we had an art exchange based on water dragon names we had come up with. Mine was Bubblestorm."
            />
            <CaptionedImages
                images={[
                    {
                        src: ranger,
                        alt: "A shabby haired warrior who weilds a silver sword and a whip."
                    },
                    {
                        src: gunslinger,
                        alt: "A ratfolk in a tunic weilding a rifle."
                    },
                    {
                        src: alchemist,
                        alt: "A red haired woman dressed in a blue cloak with gold trim."
                    },
                ]}
                caption="Sprites drawn in the style of Battle for Wesnoth"
            />
            <CaptionedImage
                image={comfort}
                alt="A Christmas-clad family sits comfortably around a fireplace as one plays guitar."
                caption="Cover for our family Christmas album. We all sang songs about comfort."
                captionPostition="right"
            />
            <CaptionedImage
                image={murrays}
                alt="Twelve JRPG style sprites of some old friends."
                caption="An old high school friend commissioned these for him and his pals."
                captionPostition="left"
                border={false}
            />
            <CaptionedImage
                image={skyline}
                alt="Skyline of Portland, Oregon"
                caption="The Portland skyline, created for a scrapped project, but I like it quite a bit so it stuck around."
            />
            <CaptionedImage
                image={dnd}
                alt="Left to right: Aife, a half-elf bard and midwife; Lady Bertilak, a wintery half-orc paladin; Mars Everson, a shy human alchemist; Grok, a tortle mystic with a passion for booklearning; Kava, a tattooless goliath fighter; Kiwi, a clever aarokocra rogue"
                caption="Group image of a DnD party. My character, an artificer named Mars, is third from the left."
                captionPostition="left"
            />
            <CaptionedImage
                image={catformer}
                alt="A mock-up for a cat themed game. A white cat leaps from a window sill to the fireplace mantle."
                caption="A mock-up of a non-existent cat themed platformer game. A catformer, if you will."
                captionPostition="right"
            />
            <CaptionedImages
                images={[
                    {
                        src: displacer,
                        alt: "A six-legged panther with two dangerous tentacles. It has manipulated light to disguise its location."
                    },
                    {
                        src: dragonGreen,
                        alt: "A slimy green dragon with yellowish fin-like wings."
                    },
                    {
                        src: fearie,
                        alt: "Greela, the queen of the winter fearies, wears a festive dress and an icy crown. She hovers in the air with her moth-like wings."
                    },
                    {
                        src: golem,
                        alt: "A giant set of iron armor. It weilds a greatsword, and its furnace-like belly emits an earie green glow."
                    },
                    {
                        src: minotaur,
                        alt: "A minotaurs skeleton is kept alive through evil magic."
                    },
                    {
                        src: owlbear,
                        alt: "This creature has the body of a bear but the face of an owl."
                    },
                ]}
                caption="Monster tokens for virtual table-top gaming."
            />

        </>
    )
}