import React from "react";
import { Helmet } from 'react-helmet';
import PageTitle from '../Components/Shared/PageTitle';
import Paragraph from '../Components/Shared/Paragraph';
import Gallery from "../Components/TokenGallery/Gallery";
import CTAContainer from '../Components/Shared/CTAContainer';
import A from '../Components/Shared/A';

import { ROLL_20, TWITTER, INSTAGRAM, KOFI, COMMISSIONS } from '../Constants/externalUrls';

export default function TokenGallery() {
    return (
        <>
            <Helmet>
                <title>Tale-Top - Token Gallery</title>
            </Helmet>
            <PageTitle>Token Gallery</PageTitle>
            <CTAContainer>
                <A src={ROLL_20}>Find my work on the Roll20 marketplace</A>
                <A internal src="art">See my other pixel art</A>
            </CTAContainer>
            <Paragraph>
                Browse my collection of character, monster, and NPC tokens in the gallery below. Click on an image for a link to the file for download. The tokens are sized for use in Roll20, but blown up to 4x so that the pixel clarity is not lost when scaling the image.
            </Paragraph>
            <Paragraph>
                If you use my tokens in your game, or if you just enjoy my work, consider following me on <A src={TWITTER}>Twitter</A> or <A src={INSTAGRAM}>Instagram</A>, or even supporting me on <A src={KOFI}>Ko-Fi</A>. Want to have a special token made for your next character? Commission your own from my <A src={COMMISSIONS}>Ko-Fi</A> for 16 dollars!
            </Paragraph>
            <Gallery />
        </>
    )
}