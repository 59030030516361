import React from "react";

import portrait from "../../Assets/SSPortrait.png"
import token from "../../Assets/HumanFighter.png"
import ttrpg from "../../Assets/Deal.png"
import Section from "./Section";

const sections = [
    {
        image: portrait,
        alt: "Pixel art self portrait",
        title: "Pixel Art",
        text: "Inspired by retro games, pixel art pieces are hand-crafted pixel mosaics.",
        linkText: "See my work >>",
        linkTo: "art"
    },
    {
        image: token,
        alt: "Glaive-weilding knight token",
        title: "Virtual Table-Top Tokens",
        text: "I am building an ever-increasing gallery of tokens for virtual table-tops, such as Roll20. Browse the many characters and monsters or consider commissioning your own.",
        linkText: "View the gallery >>",
        linkTo: "art/tokens"
    },
    {
        image: ttrpg,
        alt: "A parchment inscribed in a fantasy language.",
        title: "TTRPG Supplements",
        text: "Supplementary material for TTRPGS such as DnD. Includes new character options, GM tools, and much more!",
        linkText: "Check it out >>",
        linkTo: "ttrpg"
    },
]

export default function SectionList() {
    const Sections = sections.map(section => (
        <Section section={section} key={section.title}/>
    ));
    return (<>{Sections}</>)
}