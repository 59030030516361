import React from "react";
import styled from "styled-components";
import title from "../../Assets/CounterburyTitle.jpg";

const Title = styled.img`
    width: 80%;
    margin: 20px auto;
    max-width: 1280px;
`

const Footer = styled.div`
    display: flex;
    margin: 50px 0;
    @media (max-width: 800px) {
        flex-flow: wrap;
    }
`

const FooterSection = styled.div`
    width: 33%;
    text-align: center;
        margin-bottom: 20px;
        width: 100%;
    }
`

const A = styled.a`
    
`

const FooterHeading = styled.p`
    font-size: 1.5em;
    margin-bottom: 5px;
`

const FooterName = styled.p`
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
`

export default function ComicWrapper({ children }) {
    return (<>
        <Title src={title}></Title>
        {children}
        <Footer>
            <FooterSection>
                <FooterHeading>Find us elsewhere:</FooterHeading>
                <p>Webtoon: <A href="https://www.webtoons.com/en/canvas/counterbury-tales/list?title_no=921402&webtoon-platform-redirect=true">Counterbury Tales</A></p>
                <p>Tapas: <A href="https://tapas.io/series/Counterbury-Tales/info">Counterbury Tales</A></p>
                <p>Tumblr: <A href="https://www.tumblr.com/counterbury-tales">@Counterbury-Tales</A></p>
                <p>Bluesky: <A href="https://bsky.app/profile/counterburytales.bsky.social">@CounterburyTales.bsky.social</A></p>
                <p>Instagram: <A href="https://www.instagram.com/counterburytales/">@CounterburyTales</A></p>
                <p>Twitter: <A href="https://twitter.com/Counterbury">@Counterbury</A></p>
            </FooterSection>
            <FooterSection>
                <FooterHeading>Scotti Anderson</FooterHeading>
                <FooterName>Artist</FooterName>
                <p><A href="https://www.tale-top.com">Tale-Top.com</A></p>
                <p>Bluesky: <A href="https://bsky.app/profile/pixelemental.bsky.social">@pixelemental</A></p>
                <p>Instagram: <A href="https://www.instagram.com/picture.elemental/">@picture.elemental</A></p>
                <p>Twitter: <A href="https://twitter.com/taletopgames">@taletopgames</A></p>
                <p>Ko-fi: <A href="https://ko-fi.com/taletop">Tale-Top</A></p>
            </FooterSection>
            <FooterSection>
                <FooterHeading>Ash Shibata</FooterHeading>
                <FooterName>Writer</FooterName>
                <br />
                <FooterHeading>Tyler Higley</FooterHeading>
                <FooterName>Contributing Writer</FooterName>
                <br />
                <FooterHeading>Jesse Adamore</FooterHeading>
                <FooterName>Contributing Artist</FooterName>
            </FooterSection>
        </Footer>
    </>)
}