import React from "react";
import { Helmet } from 'react-helmet';
import PageTitle from "../Components/Shared/PageTitle";
import P from "../Components/Shared/Paragraph";
import CTAContainer from "../Components/Shared/CTAContainer";
import A from "../Components/Shared/A";
import SupplementTable from "../Components/Supplements/SupplementTable";
import { KOFI } from "../Constants/externalUrls";

export default function TTRPG() {
    return (
        <>
            <Helmet>
                <title>Tale-Top - TTRPG Supplements</title>
            </Helmet>
            <PageTitle>TTRPG Supplements</PageTitle>
            <CTAContainer>
                <A internal src="art/tokens">Check out my TTRPG tokens</A>
                <A src={KOFI}>Support me on Ko-Fi</A>
            </CTAContainer>
            <P>
                Browse my various homebrew TTRPG supplements. All supplements can be viewed online or downloaded as a PDF for free.
            </P>
            <SupplementTable />
        </>
    )
}